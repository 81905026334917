<template>
  <div
    id="app" class="app"
    :class="[
      `app--language--${language}`,
      {
        'app--show-loader': loader_visible,
      }
    ]"
  >
    <loader v-if="loader_visible" class="app__loader" />
    <!-- style="background-image: url(`/images/svg-loaders/rings.svg`)" -->
    <clear-local-storage-view />
    <integrations-header v-if="show_integrations_header && short_fio" class="app__header" />

    <header v-else class="app__header">
      <!-- TODO: extract following navigation into separate component, until temporary hidden -->
      <nav v-if="false" id="nav" class="app__navigation">
        <router-link to="/test/birthday">
          Birthday
        </router-link> |
        <!-- <router-link :to="{ name: 'anonymous', params: { user_age_group: user_age_group } }">Anonymous</router-link> | -->
        <router-link
          v-if="$store.state.users.user_age_group"
          :to="{ name: 'anonymous', params: { user_age_group: $store.state.users.user_age_group } }"
        >
          Anonymous
        </router-link> |
        <router-link to="/test/selfrating">
          Selfrating
        </router-link> |
        <router-link to="/test/bennett">
          Bennett
        </router-link> |
        <router-link to="/test/creative">
          Creative
        </router-link> |
        <router-link to="/test/critical">
          Critical
        </router-link> |
        <router-link to="/test/design">
          Design
        </router-link> |
        <router-link to="/test/digital">
          Digital
        </router-link> |
        <router-link to="/test/eqtest">
          Eq
        </router-link> |
        <router-link to="/test/gear">
          Gear
        </router-link> |
        <router-link to="/test/landolt">
          Landolt
        </router-link> |
        <router-link to="/test/naviki">
          Naviki
        </router-link> |
        <router-link to="/test/interests">
          Interests
        </router-link> |
        <router-link to="/test/faces">
          Faces
        </router-link> |
        <router-link to="/test/instruments">
          Instruments
        </router-link> |
      </nav>

      <ul v-if="show_languages_panel" class="app__language-selection languages">
        <li
          v-for="lang in languages_list" :key="lang"
          class="languages__item"
          :class="{'languages__item--selected': lang === language}"
        >
          <a
            class="languages__link"
            :class="[
              `languages__link--${lang}`,
              {'languages__link--selected': lang === language}
            ]"
            :disabled="lang === language"
            :href="`/?lang=${lang}`"
            @click.prevent="lang !== language ? set_language(lang) : false"
          >{{ lang }}</a>
        </li>
      </ul>
    </header>

    <router-view class="app__content" :class="[`app__content--${project_name}`]" />
  </div>
</template>


<script>
/* global skillfolioApi */

import { mapState, mapGetters, mapActions } from 'vuex'
import { mapProjectData } from '@/projects-config'
import integrationsHeader from '../../components/IntegrationsHeader'
import loader from '../../components/Loader'
import ClearLocalStorageView from '../../components/ClearLocalStorageView';

export default {
  components: {
    ClearLocalStorageView,
    integrationsHeader,
    loader,
  },
  data () {
    return {
      ...mapProjectData([
        'show_languages_on',
        'show_integrations_header_on'
      ]),
    }
  },
  computed: {
    ...mapState([
      'language',
      'project_name',
    ]),
    ...mapState('users', [
      'short_fio'
    ]),
    ...mapState('apis', [ 'code' ]),
    ...mapGetters([
      'show_languages',
      'available_languages',
      'loader_visible'
    ]),
    ...mapGetters('completions', [
      'completion_show_languages',
      'completion_available_languages'
    ]),
    ...mapGetters('projects', [
      'use_constructor',
      'current_project_integrations_header',
    ]),
    root_route () {
      return this.$route
        ? this.$route.matched
          ? this.$route.matched[0]
            ? this.$route.matched[0].name
            : ''
          : {}
        : {}
    },
    is_results () {
      return this.root_route === 'results'
    },
    completion_id () {
      return this.is_results ? this.$route.params.skillfolio_id : ''
    },
    show_languages_panel () {
      return this.is_results
        ? this.completion_show_languages(this.completion_id)
        : this.show_languages
    },
    languages_list () {
      return this.is_results
        ? this.completion_available_languages(this.completion_id)
        : this.available_languages
    },
    show_integrations_header () {
      return this.use_constructor
        ? this.current_project_integrations_header
        : this.show_integrations_header_on['common'].indexOf(this.project_name) > -1
      // return this.show_integrations_header_on.indexOf(this.project_name) > -1
    },
  },
  created () {
    this.show_loader()
    let vm = this

    // LOAD SKILLFOLIO API CODE EVENT HANDLING:
    document.addEventListener('SkillfolioAPIAsyncLoaded', function onSkillfolioApiLoad () {
      vm.$log.info('*** onSkillfolioApiLoad root App handler called ***, skillfolioApi:', skillfolioApi)
      if (window.skillfolioApi._loaded) {
        vm.$log.debug('*** API object loaded successfully!')
        document.removeEventListener('SkillfolioAPIAsyncLoaded', onSkillfolioApiLoad)
      } else {
        vm.$log.debug('*** !!! ERROR:: API object not fully _loaded, reloading...')
        window.skillfolioApi.init()
      }
    })

    // Run this callbacks, before closing page
    // window.addEventListener('beforeunload', this.onPageUnload)
    // window.addEventListener('unload', this.onPageUnload)
  },
  mounted () {
    this.hide_loader()
    console.log('App root mounted!!!')
  },

  methods: {
    ...mapActions([ 'show_loader', 'hide_loader', 'clear_loader', 'set_language' ]),
    ...mapActions('apis', [
      'INTEGRATION_post_message',
    ]),
    onLinkActivation (evt) {
      if (this.$appConfig.node_env === 'production') evt.preventDefault() // Disabling in-app navigation for production
      const target = evt.target
      const event_id = 'skillfolio_reload_parent'
      this.$log.debug('target.href:', target.href)
      this.$log.debug('event_id:', event_id)
      this.INTEGRATION_post_message({
        event_id: event_id,
        data: { goToUrl: target.href }
      })
    },
    // onPageUnload () {
    //   // do, before closing tab
    //   // this.clear_loader()
    // }
  }
}
</script>


<style lang="less">
  @import '../../variables';

  .app {
    position: relative;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // position: relative;
    text-align: center;
    color: @main_dark;

    // // HACK: for sensor displays && showing app into iFrame — need additional space at bottom
    // @media (hover: none) {
    //   padding-bottom: 0;
    // }

    // .only-touch-devices({
    //   padding-bottom: 0;
    // });

    // .only-safari({
    //   padding-bottom: @unitX4;
    // });
  }

  .app__navigation {  // nav
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    a {
      font-weight: bold;
      color: @main_dark;
      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .app--show-loader {
    // opacity: 0.5;

    .app__loader {
      display: block;
    }
  }

  .app__loader {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // transform: translate(-50%, -50%);
    display: block;
    width: 100%;
    height: auto;
    min-height: 100vh;
    margin: auto;
    background-color: #6cbfd2;
    background-position: center;
    background-size: 40vh;
    background-repeat: no-repeat;
    opacity: 0.75;
  }

  .app__language-selection {  // ul.languages
    position: absolute;
    z-index: 2;
    right: @unit;
    top: @unit;
    margin: 0;

    @media screen and (min-width: @bp-sm) {
      right: @unitX2;
    }
  }

  .languages {  // ul.app__language-selection
    display: flex;
    padding: 0;
    list-style: none;
  }

  .languages__item {  // li
    position: relative;
    overflow: hidden;
    padding: 0;
    // background-color: @white;
    opacity: 0.8;
    transition: background-color 0.4s ease-in, opacity 0.3s ease-in;

    &:not(:first-of-type) {
      // padding-left: @unit;
      border-left: 1px solid @blue;
    }

    &:not(:last-of-type) {
      // padding-right: @unit;
      border-right: 1px solid @blue;
    }

    &:hover,
    &:focus {
      opacity: 1;
      cursor: pointer;
      // background-color: @lightestgrey;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 200%;
      margin: auto;
      background-color: @blue;
      // transform: translateX(-100%);
      transform: translateX(-125%) skewX(-45deg);
      transition: transform 0.2s ease-in;
      will-change: transform;
    }

    &:hover::before,
    &:focus::before {
      // transform: translateX(0%);
      transform: translateX(-25%) skewX(-45deg);
    }

    &:first-of-type {
      &::before {
        // transform: translateX(100%);
        transform: translateX(75%) skewX(45deg);
      }

      &:hover::before,
      &:focus::before {
        // transform: translateX(0%);
        transform: translateX(-25%) skewX(45deg);
      }
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 200%;
      margin: auto;
      background-color: @white;
      // transform: translateX(0);
      transform: translateX(-25%) skewX(-45deg);
      transition: transform 0.2s ease-in;
      will-change: transform;
    }

    &:hover::after,
    &:focus::after {
      // transform: translateX(100%);
      transform: translateX(75%) skewX(-45deg);
    }

    &:first-of-type {
      &::after {
        // transform: translateX(0%);
        transform: translateX(-25%) skewX(45deg);
      }

      &:hover::after,
      &:focus::after {
        // transform: translateX(-100%);
        transform: translateX(-125%) skewX(45deg);
      }
    }

  }

  .languages__item--selected {
    background-color: @white;
    opacity: 1;

    &:hover,
    &:focus {
      cursor: default;
      background-color: @white;
    }

    &::before {
      display: none;
    }
  }

  .languages__link {  // a
    font-family: @roboto;
    font-size: @fz_smd;
    line-height: @lh_three_quarters;
    font-weight: 500;
    letter-spacing: normal;
    .responsiveTo(@smartphone-max-width, {
      @tp_responsive_lg();
    });
    display: block;
    padding: 4px;
    text-decoration: none;
    text-transform: uppercase;
    color: @grey;
    cursor: pointer;
    transition: color 0.4s ease-in;

    .languages__item:not(.languages__item--selected):hover &,
    .languages__item:not(.languages__item--selected):focus & {
      color: @white;
    }

    &:hover,
    &:focus {
      color: @blue;
      // text-decoration: underline;
    }

    @media screen and (min-width: @bp-md) {
      font-size: @fz_md;
      line-height: @lh_tight;
    }

    @media screen and (min-width: @tablet-landscape-middle-up) {
      font-size: @fz_lg;
      line-height: @lh_basic;
      padding: @unit_half;
    }

    @media screen and (min-width: @tablet-landscape-up) {
      padding: @unit_two_thirds;
    }

    @media screen and (min-width: @bp-lg) {
      padding: @unit;
    }
  }

  .languages__link--ru {
  }

  .languages__link--en {

  }

  .languages__link--selected {
    color: @blue;
    text-decoration: none;

    &:hover,
    &:focus {
      cursor: default;
      text-decoration: none;
    }
  }

  .app__content {
    display: flex;
    min-height: 100vh;

    .only-touch-devices({
      padding-bottom: 0;
    });

    .only-safari({
      padding-bottom: @unitX4;
    });
  }

  .app__content--foxford-m,
  .app__content--foxford-h,
  .app__content--foxford-m-1-4,
  .app__content--foxford-m-5-8,
  .app__content--foxford-h-9-11 {
    background-image: url('/images/patterns/bg-school-1.svg');
  }
  .app__content--ad1,
  .app__content--ad2,
  .app__content--ad3,
  .app__content--ad1-2021,
  .app__content--ad2-2021,
  .app__content--ad3-2021 {
    background:url('/images/patterns/bg-ad-all.svg') repeat center center fixed;
  }

  .test--knjpu {
    background: url('/images/patterns/bg-knjpu.png') repeat center center fixed;
    background-size: 25%;
  }

  .test--altai {
    background: url('/images/patterns/bg-altai.png') repeat center center fixed;
    background-size: 25% cover;
  }

  [class*=test--ranh] {
    background-color: @contrastwhite;
  }


  .app__content--my-skills-pages,
  .app__content--my-skills-h,
  .app__content--my-skills-m,
  .app__content--my-skills-algebra,
  .app__content--my-skills-biology,
  .app__content--my-skills-social-science,
  .app__content--my-skills-computer-science,
  .app__content--my-skills-english-language,
  .app__content--my-skills-russian-language {
    background-color: @skillfolio_light;
    background-image:
      url('/images/patterns/bg-my-skills-circle-average-2x.png'),
      url('/images/patterns/bg-my-skills-circle-light-2x.png'),
      url('/images/patterns/bg-my-skills-circle-dark-2x.png'),
      url('/images/patterns/bg-my-skills-waves.png');
    // background-size: cover;
    background-size: 20%, 25%, 28%, cover;
    // background-position: top center;
    background-position: 106% -58px, -10% 440px, 88% 640px, top center;
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-attachment: scroll, scroll, scroll, fixed;
  }

  // .app__content--kids-test,
  // .app__content--kids-test-clone,
  // .app__content--parents-and-kids {
  //   background-image: url('/images/patterns/bg-pattern-kids--lightest.png');
  // }


  // VueCollapse styles

  .v-collapse-content {
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
    padding: 0;
  }

  .v-collapse-content-end {
    transition: max-height 0.3s ease-in;
    // max-height: 500px;
    max-height: 1400px;
  }

  // end of VueCollapse styles


  // VModal styles

  .v--modal-block-scroll {
    overflow: inherit !important;
    width: auto !important;
  }

  .v--modal-overlay {
    max-width: 100%;
  }

  .v--modal-overlay .v--modal-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto !important;
    padding-bottom: @unitX2;
  }

</style>
