import Vue from 'vue'
import Router from 'vue-router'

import routes from './routes.js'
import config from '../../../config'

import store from '../../../store/store'

console.log('← ↑ → ← ↑ → ← ↑ → ← ↑ →')
console.log('← ↑ → router.js is loading...')
console.log('← ↑ → config.project_name:', config.project_name)
// console.log('← ↑ → routes[config.project_name]:', routes[config.project_name])

Vue.use(Router)

// export default new Router({
let router = global.router = new Router({ // makes router Global var in Node.js env
  mode: 'history',
  // TODO: here we can use custom routes trees depending on project_name
  // TODO: for now 'common' routes tree hardcoded
  // routes: routes[config.project_name],
  routes: routes['common'],
})

console.log('← ↑ → default global router:', global.router)
console.log('← ↑ → default global router base:', router.options.base)

router.beforeEach((to, from, next) => {
  console.log('■ ■ ■ beforeEach from:', from)
  console.log('■ ■ ■ beforeEach to:', to)

  const store_updated = !store.state.version || store.state.version !== config.package_version
  console.log('■ ■ ■ store_updated:', store_updated, 'store.state.version:', store.state.version, 'config.package_version:', config.package_version)
  if (store_updated) {
    console.warn('New store version released, resetting localStorage...')
    console.warn('previous version was:', store.state.version)
    console.warn('new one is:', config.package_version)
    store.dispatch('update_version', config.package_version)
    window.localStorage.removeItem('vuex')
    window.localStorage.removeItem('api_auth_token')
    window.localStorage.removeItem('api_jwt')
    document.location.reload()
  }

  let { query, ...rest_to } = to
  // let { dev, t, user_id, userId, ...rest_query } = query

  let { dev, ...rest_query_1 } = query
  if (dev) {
    console.log('■ ■ ■ dev found in GET params:', dev)
    store.dispatch('enable_dev_mode', dev)
    next({ query: rest_query_1, ...rest_to })
  }

  // let { lang, ...rest_query_2 } = rest_query_1
  // if (lang) {
  //   console.log('■ ■ ■ lang found in GET params:', lang)
  //   store.dispatch('set_language', lang)
  //   // for non-last param we should call next with remaining query,
  //   // so we sequencially returns to this guard hook
  //   next({ query: rest_query_2, ...rest_to })
  //   // for last query param we can just call next()
  //   // next()
  // }

  // let { clear_cache, ...rest_query_3 } = rest_query_2
  let { clear_cache, ...rest_query_3 } = rest_query_1
  if (clear_cache) {
    console.log('■ ■ ■ clear_cache found in GET params:', clear_cache)
    switch (clear_cache) {
    case 'tests':
      store.dispatch('tests/remove_tests_sequence')
      break
    case 'pages':
      store.dispatch('tests/remove_accessible_pages')
      break
    case 'ages':
      store.dispatch('tests/remove_project_age_group')
      break
    case 'results':
      store.dispatch('results/reset_user_results')
      store.dispatch('users/reset_user_details')
      store.dispatch('completions/remove_skillfolio_id')
      break
    case 'temp_results':
      store.dispatch('results/reset_temp_results')
      break
    case 'hash_pairs':
      store.dispatch('completions/reset_suitable_completion_hashes')
      break
    case 'completions':
      store.dispatch('completions/reset_completions')
      break
    case 'completions_hashes':
      store.dispatch('completions/reset_user_completions_hashes')
      break
    case 'all':
      store.dispatch('tests/remove_tests_sequence')
      store.dispatch('tests/remove_accessible_pages')
      store.dispatch('tests/remove_project_age_group')
      store.dispatch('results/reset_user_results')
      store.dispatch('users/reset_user_details')
      store.dispatch('completions/remove_skillfolio_id')
      store.dispatch('results/reset_temp_results')
      store.dispatch('completions/reset_suitable_completion_hashes')
      store.dispatch('completions/reset_completions')
      store.dispatch('completions/reset_user_completions_hashes')
    }

    // so we sequencially returns to this guard hook
    next({ query: rest_query_3, ...rest_to })
    // for last query param we can just call next()
    // next()
    // next({...rest_to})
  }

  // if (!dev && !lang && !clear_cache) next()
  if (!dev && !clear_cache) next()
})

// router.beforeResolve((to, from, next) => {
//   console.log('■ ■ ■ beforeResolve from:', from)
//   console.log('■ ■ ■ beforeResolve to:', to)
//   next()
// })

// router.afterEach((to, from) => {
//   console.log('■ ■ ■ afterEach from:', from)
//   console.log('■ ■ ■ afterEach to:', to)
// })

export default router
