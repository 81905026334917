<template>
  <modal name="clear-local-storage-view">
    <div class="clear-local-storage__modal">
      <div class="clear__text">
        Возникла проблема? Попробуйте очистить локальное хранилище браузера
      </div>
      <div class="clear__button" @click="clearAndReload">
        Очистить!
      </div>
      <div class="close-icon" @click="hideModal" />
    </div>
  </modal>
</template>

<script>
export default {
  name: 'clear-local-storage-view',
  created () {
    document.addEventListener('keyup', this.keyCombination, false);
  },
  beforeDestroy () {
    document.removeEventListener('keyup', this.keyCombination, false);
  },
  methods: {
    showModal () {
      this.$modal.show('clear-local-storage-view')
    },
    hideModal () {
      this.$modal.hide('clear-local-storage-view')
    },
    clearAndReload () {
      window.localStorage.removeItem('vuex')
      window.localStorage.removeItem('api_auth_token')
      window.localStorage.removeItem('api_jwt')

      document.location.reload()
    },
    keyCombination (e) {
      if (e.ctrlKey && e.shiftKey && e.key === 'L') {
        this.showModal()
      }
    }
  }
}
</script>

<style lang="less">
@import "../variables";

.clear-local-storage__modal {
  @tp_lg();
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  padding: 5% 5% 0;
}

.close-icon {
  background-image: url('../assets/i-close-cross.svg');
  width: @unitX4;
  height: @unitX4;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.clear__text {
  text-align: center;
}

.clear__button {
  margin-top: @unitX1_5;
  padding: @unit_two_thirds;
  border-radius: @unit_half;
  background-color: @red;
  color: @white;
  cursor: pointer;
}
</style>
