console.log('↑ → ↑ → ↑ → ↑ → ↑ → ↑ → ↑ → ')
console.log('↑ → routes.js is loading...')

import store from '@/store/store'
import utils from '@/utils'
import TestDataApi from '@/services/TestDataApi'


///////////////////
// Index imports //
///////////////////

const Index = () => import(/* webpackChunkName: "index-page" */`@/views/Index.vue`)


///////////////////
// Pages imports //
///////////////////

const Page = () => import(/* webpackChunkName: "page" */`@/views/Page.vue`)


///////////////////////////
// Dynamic pages imports //
///////////////////////////

// Code for switching between project-dependend page components
const Navigation = useCustomPage('Navigation').load()
const NavigationSchoolSubjects = useCustomPage('NavigationSchoolSubjects').load()
const NavigationSoftSkills = useCustomPage('NavigationSoftSkills').load()
const NavigationOnlineCourses = useCustomPage('NavigationOnlineCourses').load()


///////////////////
// Tests imports //
///////////////////

// import Test from '../views/Test.vue'
const Test = () => import(/* webpackChunkName: "test" */`@/views/Test.vue`)

const TestBirthday = () => import(`@/views/tests/default/TestBirthday.vue`)
const TestBennett = () => import(`@/views/tests/default/TestBennett.vue`)
const TestCreative = () => import(`@/views/tests/default/TestCreative.vue`)
const TestCritical = () => import(`@/views/tests/default/TestCritical.vue`)
const TestDesign = () => import(`@/views/tests/default/TestDesign.vue`)
const TestDigital = () => import(`@/views/tests/default/TestDigital.vue`)
const TestGear = () => import(`@/views/tests/default/TestGear.vue`)
const TestLandolt = () => import(`@/views/tests/default/TestLandolt.vue`)
const TestFaces = () => import(`@/views/tests/default/TestFaces.vue`)
const TestInterests = () => import(`@/views/tests/default/TestInterests.vue`)
const TestAnxiety = () => import(`@/views/tests/default/TestAnxiety.vue`)
const TestSevenCompetence = () => import(`@/views/tests/default/TestSevenCompetence.vue`)
const TestVisualLeaderProfile = () => import(`@/views/tests/default/TestVisualLeaderProfile.vue`)
const TestLocusOfControl = () => import(`@/views/tests/default/TestLocusOfControl.vue`)
const TestInstruments = () => import(`@/views/tests/default/TestInstruments.vue`)
const TestUserAccess = () => import(`@/views/tests/default/TestUserAccess.vue`)
const TestPromoAccess = () => import(`@/views/tests/default/TestPromoAccess.vue`)
const TestEducationProfilesExtended = () => import(`@/views/tests/default/TestEducationProfilesExtended.vue`)
const TestRoutedHardSkills = () => import(`@/views/tests/default/TestRoutedHardSkills.vue`)

const TestIkigai = () => import(`@/views/tests/default/TestIkigai.vue`)
const TestTiji = () => import(`@/views/tests/tiji/TestTiji.vue`)
const TestRanges = () => import(`@/views/tests/default/TestRanges.vue`)


// const ProxyTestCombo = () => import(`../../../views/tests/default/ProxyTestCombo.vue`)

const TestQuiz = () => import(`@/views/tests/default/TestQuiz.vue`)
const TestLikesSelector = () => import(`@/views/tests/default/TestLikesSelector.vue`)
const TestVisualSelector = () => import(`@/views/tests/default/TestVisualSelector.vue`)

const PageNotFound = () => import(`@/views/tests/default/PageNotFound.vue`)
const PageDisabled = () => import(`@/views/tests/default/TestDisabled.vue`)
const PageAccessDenied = () => import(`@/views/tests/default/PageAccessDenied.vue`)
const TestCompleted = () => import(`@/views/tests/default/TestCompleted.vue`)
const TestDragToImage = () => import(`@/views/tests/default/TestDragToImage.vue`)
const TestInstruction = () => import(`@/views/tests/default/TestInstruction.vue`)

///////////////////////////
// Dynamic tests imports //
///////////////////////////

// Code for switching between project-dependend test components
const TestEntrepreneur = useCustomTest('TestEntrepreneur').load()
const Pages = useCustomTest('Pages').load()
const TestInfo = useCustomTest('TestInfo').load()
const TestHello = useCustomTest('TestHello').load()
const TestAnonymous = useCustomTest('TestAnonymous').load()
// const TestPromoAccess = useCustomTest('TestPromoAccess').load()
const TestBurnout = useCustomTest('TestBurnout').load()
const TestSelfrating = useCustomTest('TestSelfrating').load()
const TestNaviki = useCustomTest('TestNaviki').load()
const TestMeta = useCustomTest('TestMeta').load()
const TestMetaMult = useCustomTest('TestMetaMult').load()
const TestInvolvement = useCustomTest('TestInvolvement').load()
const TestEducationProfiles = useCustomTest('TestEducationProfiles').load()
const TestAcademicPerformance = useCustomTest('TestAcademicPerformance').load()
const TestChildDevelopment = useCustomTest('TestChildDevelopment').load()
const TestVisualSoftSkills = useCustomTest('TestVisualSoftSkills').load()
const TestVisualRolesA = useCustomTest('TestVisualRolesA').load()
const TestVisualEducationProfiles1 = useCustomTest('TestVisualEducationProfiles1').load()
const TestVisualDisc = useCustomTest('TestVisualDisc').load()
const TestSoftSkills = useCustomTest('TestSoftSkills').load()
const TestTextSelector = useCustomTest('TestTextSelector').load()
const TestEndlessAssociations = useCustomTest('TestEndlessAssociations').load()



const ProxyTestSubject = useCustomTest('ProxyTestSubject').load()
const TestSubjectThemes = useCustomTest('TestSubjectThemes').load()
const ProxyTestTheme = useCustomTest('ProxyTestTheme').load()

const ProxyTestCombo = useCustomTest('ProxyTestCombo').load()

const TestConfirm = useCustomTest('TestConfirm').load()
const TestEq = useCustomTest('TestEq').load()
const TestEqDrivers = useCustomTest('TestEqDrivers').load()
const TestEqDriverAdaptability = useCustomTest('TestEqDriverAdaptability').load()
const TestEqDriverSelfesteem = useCustomTest('TestEqDriverSelfesteem').load()
const TestLocusOfControlRating = useCustomTest('TestLocusOfControlRating').load()
const TestWeightedChoice = useCustomTest('TestWeightedChoice').load()
const TestFinrating = useCustomTest('TestFinrating').load()
const TestBusinessCompetence = useCustomTest('TestBusinessCompetence').load()
const TestForm = useCustomTest('TestForm').load()
const TestMultipurpose = useCustomTest('TestMultipurpose').load()
const TestSchoolGrade = useCustomTest('TestSchoolGrade').load()

////////////////////
// Signup imports //
////////////////////

// import Signup from '../views/Signup.vue'
const Signup = () => import(/* webpackChunkName: "signup" */`@/views/Signup.vue`)


/////////////////////
// Results imports //
/////////////////////

// import Results from '../views/Results.vue'
const Results = () => import(/* webpackPrefetch: true *//* webpackChunkName: "results" */`@/views/Results.vue`)


////////////////////////
// Working on imports //
////////////////////////

const WorkingOn = () => import(/* webpackPrefetch: true *//* webpackChunkName: "workingOn" */`@/views/WorkingOn.vue`)


///////////////////////
// Utility functions //
///////////////////////

function useCustomPage (pageFileName) {
  const pageComputedName = utils.transformTest.fileToCleanName(pageFileName)
  return {
    load: pageCustomName => {
      return () => {
        const views_dir = store.getters['tests/project_pages_directories'][pageCustomName || pageComputedName]
        console.log(`!!! Page ${pageFileName} views_dir is:`, views_dir)
        return import(`@/views/pages/` + views_dir + `${pageFileName}.vue`)
      }
    }
  }
}

function useCustomTest (testFileName) {
  const testComputedName = utils.transformTest.fileToCleanName(testFileName)
  const testName = utils.transformTest.fileToName(testFileName)
  return {
    load: testCustomName => {
      return () => {
        const constructorAvailable = store.getters['projects/use_constructor'] && store.getters['projects/current_project_tests']
        const constructorDefinedTests = constructorAvailable
          ? store.getters['projects/get_current_selected_tests'](testCustomName || testName)
          : []
        const constructorDefinedTest = constructorDefinedTests[0]
        const views_dir = constructorDefinedTest
          ? constructorDefinedTest.is_custom
            ? constructorDefinedTest.directory !== null ? constructorDefinedTest.directory + '/' : store.state.project_name + '/'
            : 'default/'
          : store.getters['tests/project_tests_directories'][testCustomName || testComputedName]

        // const views_dir = store.getters['tests/project_tests_directories'][testCustomName || testComputedName]
        console.log(`!!! Test ${testFileName} views_dir is:`, views_dir)
        return import(/* webpackChunkName: "[request]" */`@/views/tests/` + views_dir + `${testFileName}.vue`)
      }
    }
  }
}


//////////////////
// Routes trees //
//////////////////

export default {
  // default hardcoded route tree
  common: [
    {
      path: '/',
      name: 'index',
      component: Index,
      // redirect: {
      //   name: 'page'
      // },
      // redirect: to => {
      //   return {name: ''}
      // },
      beforeEnter: beforeEnterChecks,
    },
    // TODO: make next route naming right, and working
    {
      path: '/page',
      name: 'page',
      component: Page,
      // redirect: {
      //   name: 'navigation'
      // },
      children: [
        {
          path: '/navigation',
          name: 'navigation',
          component: Navigation,
          // redirect: {
          //   name: 'school-subjects'
          // },
          children: [
            {
              path: 'school-subjects',
              name: 'navigation-school-subjects',
              component: NavigationSchoolSubjects,
            },
            {
              path: 'soft-skills',
              name: 'navigation-soft-skills',
              component: NavigationSoftSkills,
            },
            {
              path: 'online-courses',
              name: 'navigation-online-courses',
              component: NavigationOnlineCourses,
            },
          ]
        },
      ],
      beforeEnter: beforeEnterChecks,
    },
    {
      path: '/test',
      name: 'test',
      component: Test,
      children: [
        {
          path: '/hello',
          name: 'hello',
          component: TestHello,
        },
        {
          path: '/instruction',
          name: 'instruction',
          component: TestInstruction,
        },
        {
          path: '/info',
          name: 'info',
          component: TestInfo,
        },
        {
          path: '/user-access',
          name: 'user-access',
          component: TestUserAccess,
        },
        {
          path: '/promo-access',
          name: 'promo-access',
          component: TestPromoAccess,
          props: true
        },
        {
          path: '/birthday',
          name: 'birthday',
          component: TestBirthday,
        },
        {
          path: '/anonymous/:user_age_group',
          name: 'anonymous',
          component: TestAnonymous,
          props: true
        },
        {
          path: '/ranges',
          name: 'ranges',
          component: TestRanges
        },
        {
          path: 'selfrating',
          name: 'selfrating',
          component: TestSelfrating,
        },
        {
          path: '/soft-skills',
          name: 'soft-skills',
          component: TestSoftSkills,
        },
        {
          path: 'bennett',
          name: 'bennett',
          component: TestBennett,
        },
        {
          path: 'entrepreneur',
          name: 'entrepreneur',
          component: TestEntrepreneur,
        },
        {
          path: 'creative',
          name: 'creative',
          component: TestCreative,
        },
        {
          path: 'routed-hard-skills',
          name: 'routed-hard-skills',
          component: TestRoutedHardSkills,
        },
        {
          path: 'critical',
          name: 'critical',
          component: TestCritical,
        },
        {
          path: 'design',
          name: 'design',
          component: TestDesign,
        },
        {
          path: 'digital',
          name: 'digital',
          component: TestDigital,
        },
        {
          path: 'eqtest',
          name: 'eqtest',
          component: TestEq,
        },
        {
          path: 'eq-drivers/:type',
          name: 'eq-drivers',
          component: TestEqDrivers,
          props: true,
        },
        {
          path: 'eq-driver/adaptability',
          name: 'eq-driver-adaptability',
          component: TestEqDriverAdaptability,
        },
        {
          path: 'eq-driver/selfesteem',
          name: 'eq-driver-selfesteem',
          component: TestEqDriverSelfesteem,
        },
        {
          path: 'gear',
          name: 'gear',
          component: TestGear,
        },
        {
          path: 'landolt',
          name: 'landolt',
          component: TestLandolt,
        },
        {
          path: 'naviki',
          name: 'naviki',
          component: TestNaviki,
        },
        {
          path: 'meta',
          name: 'meta',
          component: TestMeta,
        },
        {
          path: 'anxiety',
          name: 'anxiety',
          component: TestAnxiety,
        },
        {
          path: 'seven-competence',
          name: 'seven-competence',
          component: TestSevenCompetence,
        },
        {
          path: 'visual-leader-profile',
          name: 'visual-leader-profile',
          component: TestVisualLeaderProfile,
        },
        {
          path: 'involvement',
          name: 'involvement',
          component: TestInvolvement,
        },
        {
          path: 'burnout',
          name: 'burnout',
          component: TestBurnout,
        },
        {
          path: 'locus-of-control',
          name: 'locus-of-control',
          component: TestLocusOfControl,
        },
        {
          path: 'locus-of-control-rating',
          name: 'locus-of-control-rating',
          component: TestLocusOfControlRating,
        },
        {
          path: 'academic-performance',
          name: 'academic-performance',
          component: TestAcademicPerformance,
        },
        {
          path: 'child-development',
          name: 'child-development',
          component: TestChildDevelopment,
        },
        {
          path: 'interests',
          name: 'interests',
          component: TestInterests,
        },
        {
          path: 'faces',
          name: 'faces',
          component: TestFaces,
        },
        {
          path: 'instruments',
          name: 'instruments',
          component: TestInstruments,
        },
        {
          path: 'ikigai',
          name: 'ikigai',
          component: TestIkigai,
        },
        {
          path: 'education-profiles',
          name: 'education-profiles',
          component: TestEducationProfiles,
        },
        {
          path: 'tiji',
          name: 'tiji',
          component: TestTiji,
        },
        {
          path: 'meta-mult',
          name: 'meta-mult',
          component: TestMetaMult,
        },
        {
          path: 'quiz',
          name: 'quiz',
          component: TestQuiz,
        },
        {
          path: 'likes-selector',
          name: 'likes-selector',
          component: TestLikesSelector,
        },
        {
          path: 'visual-selector',
          name: 'visual-selector',
          component: TestVisualSelector,
        },
        {
          path: 'visual-soft-skills',
          name: 'visual-soft-skills',
          component: TestVisualSoftSkills,
        },
        {
          path: 'visual-roles-a',
          name: 'visual-roles-a',
          component: TestVisualRolesA,
        },
        {
          path: 'visual-education-profiles-1',
          name: 'visual-education-profiles-1',
          component: TestVisualEducationProfiles1,
        },
        {
          path: 'education-profiles-extended',
          name: 'education-profiles-extended',
          component: TestEducationProfilesExtended,
        },
        {
          path: 'visual-disc',
          name: 'visual-disc',
          component: TestVisualDisc,
        },
        {
          path: 'text-selector',
          name: 'text-selector',
          component: TestTextSelector,
        },
        {
          path: 'endless-associations',
          name: 'endless-associations',
          component: TestEndlessAssociations,
        },
        {
          path: 'drag-to-image',
          name: 'drag-to-image',
          component: TestDragToImage,
        },
        {
          path: 'finrating',
          name: 'finrating',
          component: TestFinrating,
        },
        {
          path: 'weighted-choice',
          name: 'weighted-choice',
          component: TestWeightedChoice,
        },
        {
          path: 'business-competence',
          name: 'business-competence',
          component: TestBusinessCompetence
        },
        {
          path: 'form',
          name: 'form',
          component: TestForm
        },
        {
          path: 'school-grade',
          name: 'school-grade',
          component: TestSchoolGrade
        },
        {
          path: 'multipurpose',
          name: 'multipurpose',
          component: TestMultipurpose
        },
        // TODO: add categories and subjects routes:
        // categories > default:subjects | subject
        {
          path: 'subjects/:category/:subject',
          name: 'subject', // list of subject themes
          component: ProxyTestSubject,
          props: true,
          redirect: {
            name: 'themes'
          },
          children: [
            {
              alias: '',
              path: 'themes',
              name: 'themes',
              component: TestSubjectThemes,
            },
            {
              path: 'themes/:theme',
              name: 'theme', // test with theme questions
              // component: TestSubjectTheme,
              component: ProxyTestTheme,
              props: true,
            },
          ],
        },
        {
          path: 'combo/:type',
          name: 'combo',
          component: ProxyTestCombo,
          props: true
        },
        {
          path: '/pages',
          name: 'pages',
          component: Pages,
          // children: [
          //   {
          //     path: 'soft-skills',
          //     name: 'soft-skills',
          //     component: PagesSoftSkills,
          //   },
          //   {
          //     path: 'school-subjects',
          //     name: 'school-subjects',
          //     component: PagesSchoolSubjects,
          //   },
          // ]
        },
        {
          path: '/confirm',
          name: 'confirm',
          component: TestConfirm,
        },
        {
          path: '/completed',
          name: 'completed',
          component: TestCompleted,
        },
        {
          path: '/not-found',
          name: 'not-found',
          component: PageNotFound,
        },
        {
          path: '/disabled',
          name: 'disabled',
          component: PageDisabled,
        },
        {
          path: '/access-denied',
          name: 'access-denied',
          component: PageAccessDenied,
        },
      ],
      beforeEnter: beforeEnterChecks,
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      beforeEnter (to, from, next) {
        console.log('■ ■ beforeEnter to:', to)
        store.dispatch('show_loader')
        if (to.name === 'signup' && !store.getters['tests/project_tests_completed']) {
          console.log('Sorry, you need to complete all tests first!')
          store.dispatch('hide_loader')
          next({ name: 'index' })
        }
        next()
      }
    },
    {
      path: '/results/:skillfolio_id',
      name: 'results',
      component: Results,
      props: true,
      beforeEnter (to, from, next) {
        console.log('■ ■ beforeEnter to:', to)
        console.log('■ ■ route skillfolio_id param is:', to.params.skillfolio_id)
        store.dispatch('show_loader')
        console.log(`■ ■ store.getters['completions/user_completion'](${to.params.skillfolio_id}):`, store.getters['completions/user_completion'](to.params.skillfolio_id))
        // const useLocalStorageResults = (store.getters['completions/user_completion'](to.params.skillfolio_id)) ? true : false
        store.dispatch('apis/SKILLFOLIO_get_and_save_completion', to.params.skillfolio_id)
          .then(completion => {
            console.log('XXXXXX:completions:', completion)
            console.log('XXXXXX:project_data:', store.getters['projects/get_project_data'](completion.results.mode))
            store.dispatch('projects/remove_project_data', completion.results.mode)
            console.log('XXXXXXX:try use constructor')
            return TestDataApi.getProjectResultsConfig(completion.results.mode)
              .then(response => response.data)
              .then(constructorData => {
                store.dispatch('projects/set_project_data', [ completion.results.mode, constructorData ])
                store.dispatch('set_just_project_name', completion.results.mode)
                if (store.getters['completions/completion_available_languages'](to.params.skillfolio_id).indexOf(store.state.language) === -1) {
                  // console.log('heyy3', store.getters['completions/completion_available_languages'](to.params.skillfolio_id)[0])
                  store.dispatch('set_language', store.getters['completions/completion_available_languages'](to.params.skillfolio_id)[0])
                }
                return constructorData.allow_multiple_completions
                  ? store.dispatch('allow_multiple_completions')
                  : store.dispatch('forbid_multiple_completions')
              })
              .catch(err => console.warn('Can\'t load results data from constructor:', err))
          })
          .then(() => {
            console.log('Loading completion finished!')
            next()
          })
          .catch(err => {
            console.log('apis/SKILLFOLIO_get_and_save_completion error is:', err)
          })
      }
    },
    // {
    //   // path: '/working-on/:wrappingComponentName/:componentType/:componentProject/:componentName',
    //   path: '/working-on',
    //   name: 'working-on',
    //   component: WorkingOn,
    //   props: true,
    //   children: [
    //   ]
    // }
    {
      path: '/working-on/test/:componentProject/:componentName',
      name: 'working-on-test',
      component: WorkingOn,
      props: route => ({
        wrappingComponentName: 'Test',
        componentsDir: 'views',
        componentType: 'tests',
        componentProject: route.params.componentProject,
        componentName: route.params.componentName,
      }),
    },
    {
      path: '/working-on/result/:componentName',
      name: 'working-on-result',
      component: WorkingOn,
      props: route => ({
        wrappingComponentName: 'Results',
        componentsDir: 'views',
        componentType: 'results',
        componentName: route.params.componentName,
      }),
    },
    {
      path: '/working-on/result/:componentName/:skillfolioId',
      name: 'working-on-result-done',
      component: WorkingOn,
      props: route => ({
        wrappingComponentName: 'Results',
        componentsDir: 'views',
        componentType: 'results',
        componentName: route.params.componentName,
        skillfolioId: route.params.skillfolioId,
      }),
    },
    {
      path: '/working-on/result/:componentName/from-file/:dataFileName',
      name: 'working-on-result-from-file',
      component: WorkingOn,
      props: route => ({
        wrappingComponentName: 'Results',
        componentsDir: 'views',
        componentType: 'results',
        componentName: route.params.componentName,
        dataFileName: route.params.dataFileName,
      }),
    },
    {
      path: '/working-on/page/:componentProject/:componentName',
      name: 'working-on-page',
      component: WorkingOn,
      props: route => ({
        wrappingComponentName: 'Page',
        componentsDir: 'views',
        componentType: 'pages',
        componentProject: route.params.componentProject,
        componentName: route.params.componentName,
      }),
    },
    {
      path: '/working-on/component/:componentName',
      name: 'working-on-component',
      component: WorkingOn,
      props: route => ({
        componentsDir: 'components',
        componentName: route.params.componentName,
      }),
    },
    {
      path: '/working-on/widget-component/:componentName',
      name: 'working-on-widget-component',
      component: WorkingOn,
      props: route => ({
        componentsDir: 'components',
        componentType: 'widgets',
        componentName: route.params.componentName,
      }),
    },
    {
      path: '/working-on/question-component/:componentName',
      name: 'working-on-question-component',
      component: WorkingOn,
      props: route => ({
        componentsDir: 'components',
        componentType: 'questions',
        componentName: route.params.componentName,
      }),
    },
    {
      path: '/working-on/mech-component/:componentName',
      name: 'working-on-mech-component',
      component: WorkingOn,
      props: route => ({
        componentsDir: 'components',
        componentType: 'mechanics',
        componentName: route.params.componentName,
      }),
    },
    {
      path: '/working-on/some/:componentsDir/:componentName',
      name: 'working-on-some',
      component: WorkingOn,
      props: route => ({
        componentsDir: route.params.componentsDir,
        componentName: route.params.componentName,
      }),
    },
    // ]
    // },
  ],

  // this is the way to customize route trees for projects
  asi: [],
  skillfolio: [],
  'caseid-skillfolio': [],
  future: [],
  asi2: [],
  test: [],
  mcko: [],
  rosel: [],
  // etc
}


//////////////////
// Common Hooks //
//////////////////

function beforeEnterChecks (to, from, next) {
  console.log('■ ■ beforeEnterChecks() called')
  console.log('■ ■ beforeEnter to:', to)
  store.dispatch('show_loader')

  let { query, ...rest_to } = to
  // let { utm_source, userId, ...rest_query } = query

  let { t, ...rest_query0 } = query
  // console.log('■ ■ t =', t, '| rest_query after `t` derived:', rest_query0)

  if (t) { // test, sets mode and project_name
    console.log('■ ■ t found in GET params:', t)
    store.dispatch('set_project_name', t)
  }

  let { lang, ...rest_query1 } = rest_query0
  // console.log('■ ■ lang =', lang, '| rest_query after `lang` derived:', rest_query1)
  if (lang) {
    console.log('■ ■ lang found in GET params:', lang)
    store.dispatch('set_language', lang)
    // next({ query: rest_query1, ...rest_to })
  }

  let { utm_source, userId, ...rest_query2 } = rest_query1
  // console.log('■ ■ utm_source =', utm_source, ', userId =', userId, '| rest_query2 after `utm_source` and `userId` derived:', rest_query2)

  if (utm_source) { // saves utm_source to test results
    console.log('■ ■ WOW! utm_source GET param found:', utm_source)
    store.dispatch('results/save_result', [ 'utm_source', utm_source ])
    store.dispatch('hide_loader')
    next({ query: rest_query2, ...rest_to })
  } else if (userId) { // sets asi_uid and update test results
    console.log('■ ■ WOW! userId GET param found:', userId)
    store.dispatch('users/set_asi_uid', userId)
    // store.dispatch('users/set_user', userId)  // FIXIT: if there is user_id and userId, second always override first, should be another way
    store.dispatch('hide_loader')
    next({ query: rest_query2, ...rest_to })
  }

  let { cb, ...rest_query3 } = rest_query2
  // console.log('■ ■ cb =', cb, '| rest_query3 after `cb` derived:', rest_query3)
  if (cb) {
    console.log('■ ■ cb found in GET params:', cb)
    store.dispatch('apis/set_callback_url', cb)
    // next({ query: rest_query3, ...rest_to })
  }

  let { use_xhr_req, ...rest_query4 } = rest_query3
  // console.log('■ ■ use_xhr_req =', use_xhr_req, '| rest_query4 after `use_xhr_req` derived:', rest_query4)
  if (use_xhr_req) {
    console.log('■ ■ use_xhr_req found in GET params:', use_xhr_req)
    store.dispatch('apis/set_callback_xhr_req', use_xhr_req)
    // next({ query: rest_query4, ...rest_to })
  }

  // for ASI (or any other) tokenName@tokenValue token type projects
  let { token, ...rest_query5 } = rest_query4
  // console.log('■ ■ token =', token, '| rest_query5 after `token` derived:', rest_query5)
  if (token) {
    // console.log('■ ■ token found in GET params:', token)
    store.dispatch('apis/set_token_pair', token)
    // next({ query: rest_query5, ...rest_to })
  }

  let { ran_token, ...rest_query6 } = rest_query5
  // console.log('■ ■ ran_token =', ran_token, '| rest_query6 after `ran_token` derived:', rest_query6)
  if (ran_token) {
    // console.log('■ ■ ran_token found in GET params:', ran_token)
    store.dispatch('apis/set_ran_token', ran_token)
    // next({ query: rest_query6, ...rest_to })
  }

  let { unti_token, activity_uuid, ...rest_query7 } = rest_query6
  // console.log('■ ■ unti_token =', unti_token, 'activity_uuid =', activity_uuid, '| rest_query7 after `unti_token` and `activity_uuid` derived:', rest_query7)
  if (unti_token) {
    console.log('■ ■ unti_token found in GET params:', unti_token)
    store.dispatch('apis/set_token', [ 'unti', unti_token ])
  }
  if (activity_uuid) {
    console.log('■ ■ activity_uuid found in GET params:', activity_uuid)
    store.dispatch('apis/set_activity_uuid', activity_uuid)
  }
  if (unti_token && activity_uuid) {
    store.dispatch('apis/start_unti_testing')
  } else if (unti_token && !activity_uuid || !unti_token && activity_uuid) console.warn('unti integration built for using `unti_token` and `activity_uuid` simultaneously...')

  let { redirect_url, ...rest_query8 } = rest_query7
  // console.log('■ ■ redirect_url =', redirect_url, '| rest_query8 after `redirect_url` derived:', rest_query8)
  if (redirect_url) {
    console.log('■ ■ redirect_url found in GET params:', redirect_url)
    store.dispatch('apis/set_redirect_url', redirect_url)
    // next({ query: rest_query8, ...rest_to })
  }

  // for MySkills (or any other) tripleDes GUID decoding
  let { code, ...rest_query9 } = rest_query8
  // console.log('■ ■ code =', code, '| rest_query9 after `code` derived:', rest_query9)

  let { user_age, ...rest_query10 } = rest_query9
  // console.log('■ ■ user_age =', user_age, '| rest_query10 after `user_age` derived:', rest_query10)

  let { user_id, ...rest_query11 } = rest_query10
  // console.log('■ ■ user_id =', user_id, '| rest_query12 after `user_id` derived:', rest_query11)

  // MEMO: new and proper way for doing integrations
  let { integration, ...rest_query12 } = rest_query11
  if (integration) {
    console.log('■ ■ integration =', integration, '| rest_query12 after `integration` derived:', rest_query12)
    let fixed_integration = integration.replaceAll(' ', '+')
    const integration_data = JSON.parse(decodeURIComponent(escape(atob(decodeURIComponent(fixed_integration)))))
    integration_data.firstname = integration_data.fio.split(' ')[0]
    integration_data.lastname = integration_data.fio.split(' ')[1]
    console.log('■ ■ integration data =', integration_data)
    store.dispatch('users/set_auth_user', integration_data)
    if (integration_data.user_id) user_id = String(integration_data.user_id)
    if (integration_data.user_age) user_age = integration_data.user_age
    if (integration_data.auth_token) store.dispatch('users/set_auth_token', integration_data.auth_token)
    if (integration_data.lang) store.dispatch('set_language', integration_data.lang)
    store.dispatch('apis/set_integration', integration_data)
  }

  if (user_age) {
    console.log('■ ■ user_age found in GET params:', user_age)
    store.dispatch('users/set_user_age', parseInt(user_age, 10))
  }


  if (code) {
    // code=G4fdQ2Ln4ZUeJRyWqw0Q9aQi0sSOkqdHRAUqSCYe3lxABWxR7nuXMQcNltbXPRTKU0%2F5EIXDUXuyGvC4t5AtpUUK5jfvC97Iq9TEuezHuNyZq0LvOMMMSFQ%2BB0iXOy84xj8uUr%2FatfXm9YvbIw2ofHMoxyblGLOy2UgTZFFgs%2F0%3D
    // code=G4fdQ2Ln4ZVhCwJNesbnPSMuJVvRW0B7kbPHOQ1jZwTv%2FlLQtyYzjTJ9WsEFa%2B6z%2BVU27cGELGvT2aZEDN0ofoTzj9zodtHDxPKwT3bfUG7UGCgk90B3HMWnosh2Nu8IfShPlPk1TUq4wqRClZzPpSCWBH28TIM7
    console.log('■ ■ ■ code found in GET params:', code)
    // store.dispatch('apis/SKILLFOLIO_decrypt_tripleDes_code', encodeURIComponent(code))
    store.dispatch('apis/set_code', code) // sync but returns promise
      .then(() => store.dispatch('apis/SKILLFOLIO_decrypt_tripleDes_code', store.state.apis.code))
      .then(response => {
        console.log('decryptor response:', response)
        if (response.ShortFio) {
          store.dispatch('users/set_short_fio', response.ShortFio)
          store.dispatch('apis/INTEGRATION_post_message', {
            event_id: 'skillfolio_get_short_fio',
            data: { shortFio: response.ShortFio }
          })
        } else {
          store.dispatch('apis/INTEGRATION_post_message', {
            event_id: 'skillfolio_get_short_fio',
            data: {}
          })
        }
        if (response.Parallel) {
          store.dispatch('users/set_parallel', response.Parallel)
        }
        if (response.IsMoscowStudent) {
          store.dispatch('users/set_moscow_student', response.IsMoscowStudent)
        }
        store.dispatch('hide_loader')
        next({ query: { ...rest_query10, user_id: response.Guid }, ...rest_to })
      })
      .catch(err => {
        console.log('decryptor error:', err)
        console.warn(err)
        store.dispatch('apis/INTEGRATION_post_message', {
          event_id: 'skillfolio_get_short_fio',
          data: new Error(err)
        })
        store.dispatch('hide_loader')
        // so we sequencially returns to this guard hook
        next({ query: rest_query10, ...rest_to })
        // for last query param we can just call next()
        // next()
        // next({...rest_to})
      })
  } else if (user_id || userId) { // sets user_id and update results_calculated
    if (user_id) console.log('■ ■ user_id found in GET params:', user_id)
    if (userId) console.log('■ ■ userId found in GET params:', userId)

    const uid = user_id || userId
    store.dispatch('users/set_user', (uid && uid !== 'null') ? uid : null)
      .then(() => {
        if (uid !== 'null') {
          onUserIdFound(uid, next)
        } else {
          // No user_id in get params
          console.log('■ ■ No user id in GET params...')
          next()
        }
      })

  } else if (store.state.users.user_id != null) {
    console.log('■ ■ state.users.user_id found in store:', store.state.users.user_id)
    store.dispatch('users/set_user', store.state.users.user_id)
      .then(() => {
        onUserIdFound(store.state.users.user_id, next)
      })
  } else {
    // No user_id in get params
    console.log('■ ■ No user id in GET params...')
    next()
  }


  async function onUserIdFound (uid, next) {
    console.log(`onUserIdFound(${uid}, next) called!`)
    // FIXME: here can be checked local completion by user_id or email
    // but be aware of that user can't pass test more then one time by default
    // upd: if no state.multiple_completions given
    // upd: add complex behaviour for multiple_completions

    if (store.getters.show_prev_results && store.state.multiple_completions) {
      console.log('■ ■ Multiple completions & show prev results enabled for project')
      try {
        const hashes_and_dates = await store.dispatch('apis/SKILLFOLIO_get_user_completion_hashes')
        await store.dispatch('completions/add_user_completions_hashes', [ store.state.project_name, uid, hashes_and_dates ])
        return next()
      } catch (err) {
        console.log('■ ■========== ■ ■')
        console.warn('■ ■ No user completion for user id:', uid)

        const load_event_id = 'skillfolio_load_test'
        store.dispatch('apis/INTEGRATION_post_message', {
          event_id: load_event_id,
          data: { isPassed: false }
        })
        return next()
      }
    } else if (store.state.multiple_completions) {
      console.log('■ ■ Multiple completions enabled for project')
      return next()
    }

    console.log('Multiple completions disabled for project!')

    store.dispatch('apis/SKILLFOLIO_get_user_last_completion_hash')
      .then(last_completion_hash => {
        console.log('■ ■ ========== ■ ■')
        console.log('■ ■ Last result for User id found:', last_completion_hash)
        store.dispatch('hide_loader')
        // Action returns skillfolio_id from API call
        return next({
          name: 'results',
          params: { skillfolio_id: last_completion_hash }
        })
      })
      .catch(() => {
        // Action throws error from API call
        console.log('■ ■========== ■ ■')
        console.warn('■ ■ No user completion for user id:', uid)

        const load_event_id = 'skillfolio_load_test'
        store.dispatch('apis/INTEGRATION_post_message', {
          event_id: load_event_id,
          data: { isPassed: false }
        })
      })
      .then(() => {
        // custom logic for outer testing:
        if (store.getters.is_outer_testing) {
          console.log('Additional actions for outer testing ran...')
          // get suitable inner completion and merge some of it's results and
          // info into current results & info, then go to next test task
          store.dispatch('completions/get_suitable_inner_completion', {
            project_name: store.state.project_name,
            user_id: uid,
          })
            .then(inner_completion => { // need to copy some info & results from inner completion to new one...
              console.log('Inner completion received:', inner_completion)
              store.dispatch('results/add_to_info', {
                gender: inner_completion.info.gender
              })
              store.dispatch('results/save_result', [ 'birthday', inner_completion.results.birthday, null, { age_group: inner_completion.results.mode_sub.age }])
            })
            .catch(err => {
              console.warn('No inner testing found:', err)
              // TODO: add redirect to next({ accessDenied })
            })
        }

        console.log('■ ■ exiting beforeEnter hook, going to beforeRouteEnter in-component hook')
        return next()
      })
  }
}
